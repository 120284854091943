export default defineAppConfig({
  saasName: 'La Tienda Home',
  customSaas: true,
  languageStrings: {
    company: 'Departamento',
    companies: 'Departamentos',
    briefing: {
      visibility: 'Si el briefing se pone en "oculto" únicamente lo visualizarán los usuarios admin, y en "visible" todos los usuarios de cualquier departamento.'
    }
  },
  meta: {
    adAccounts: [
      {
        name: 'Redes Lesap (116508232151666)',
        act: 116508232151666
      }
    ]
  }
})